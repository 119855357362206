<template>
    <div>
        <div class="flex">
            <div
                class="pr-12 custom-radio"
                :key="option"
                v-for="option in options"
            >
                <label class="container flex my-5 leading-14">{{option}}
                    <input
                        @change='$emit("checked", option)'
                        type="radio"
                        :id="name"
                        :value="option"
                        :name="name"
                        :checked="selected === option || (selected === 1 && option === option1) || (selected === 0 && option === option0) || (selected === null && option === optionNull)"
                    >
                    <span class="checkmark"></span>
                </label>
            </div>
        </div>
        <p
            v-if="error"
            class="text-red-500 small mb-22 inputErrors"
        >
            {{error[0]}}
        </p>
    </div>
</template>

<script>
export default {
    name: 'Radio',
    data () {
        return {
            option1: 'Da',
            option0: 'Nu',
            optionNull: 'Necunoscut'
        }
    },
    props: ['options', 'name', 'selected', 'error']
}
</script>
