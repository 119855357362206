<template>
    <div>
        <label
            :for="id"
            class="flex items-center"
        >
            <!-- label1 -->
            <div class="mr-12 text-gray-700">
                {{label1}}
            </div>
            <!-- toggle -->
            <div class="relative">
                <!-- input -->
                <input
                    @change='$emit("input", $event.target.checked)'
                    :id="id"
                    type="checkbox"
                    class="sr-only toggle"
                    :checked="checked"
                    :default-checked.prop="checked"
                    :disabled="disabled" />
                <!-- line -->
                <div class="line w-32 h-12 bg-gray-200 rounded-full shadow-inner"></div>
                <!-- dot -->
                <div class="dot absolute w-20 h-20 bg-gray-500 rounded-full shadow -left-4 -top-4 transition"></div>
            </div>
            <!-- label2 -->
            <div class="ml-12 text-gray-700">
                {{label2}}
            </div>
        </label>
        <p
            v-if="error"
            class="text-red-500 small mb-22 inputErrors"
        >
            {{error[0]}}
        </p>
    </div>
</template>

<script>
export default {
    name: 'Toggle',
    props: ['label1', 'label2', 'id', 'disabled', 'checked', 'error']
}
</script>
