<template>
    <div class="grid grid-cols-12">
        <!-- Title -->
        <div class="col-span-12 md:col-span-6 page-title ml-30 mb-20 mt-30 lg:pl-20">
            {{$t('newCaseTitle')}}
        </div>
        <!-- Actions buttons -->
        <div class="col-span-12 md:col-span-6 flex md:justify-end md:items-center mr-30 lg:pr-20 ml-30 md:ml-0 mb-30 md:mb-0 relative">
            <Button
                @btnClick="onCaseAddDraft"
                class="button bg-gray-500 hover:bg-gray-600 text-white w-257 mr-22"
                role="submit"
                :text="$t('saveAsDraftButtonText')"
                :disabled="caseAddDraftLoading"
                :spinning="caseAddDraftLoading"
            />
            <Button
                @btnClick="onCaseAdd"
                class="button bg-purple-500 text-white hover:bg-purple-600 w-150"
                role="submit"
                :text="$t('saveCaseButtonText')"
                :disabled="caseAddLoading"
                :spinning="caseAddLoading"
            />
            <!-- Confirmation box for success messages -->
            <div class="fixed top-20 right-30 z-10000" ref="successBoxContainer">
            </div>
        </div>
        <div ref="body" class="col-span-12">
            <div class="bg-white mx-30 mb-30 rounded-lg p-20 shadow">
                <!-- Date despre victima -->
                <Title
                    class="col-span-6"
                    :title="$t('victimDetailsTitle')"
                    :subtitle="$t('victimDetailsSubtitle')"
                />
                <hr class="col-span-12 mb-30">
                <!-- Detalii personale -->
                <div class="grid lg:grid-cols-2 xl:grid-cols-3 gap-x-30">
                    <Input
                        v-model="caseAdd.victim.first_name"
                        :error="errors['victim.first_name']"
                        :class="[errors['victim.first_name'] ? 'mb-4' : 'mb-20']"
                        :label="$t('victimFirstNameLabel')"
                        :placeholder="$t('victimFirstNamePlaceholder')"
                        type="text"
                        name="victim_first_name"
                    />
                    <Input
                        v-model="caseAdd.victim.last_name"
                        :error="errors['victim.last_name']"
                        :class="[errors['victim.last_name'] ? 'mb-4' : 'mb-20']"
                        :label="$t('victimLastNameLabel')"
                        :placeholder="$t('victimLastNamePlaceholder')"
                        type="text"
                        name="victim_last_name"
                    />
                    <div class="mb-20">
                        <label class="flex">{{$t('victimMaritalStatusLabel')}}</label>
                        <Multiselect
                            v-model="caseAdd.victim.marital_status"
                            :options="marital_statuses"
                            class="rounded border"
                            :class="[errors['victim.marital_status.id'] ? 'border-red-500' : 'border-gray-200']"
                            label="name"
                            track-by="name"
                            :placeholder="$t('victimMaritalStatusPlaceholder')"
                            :deselectLabel="$t('selectRemoveOptionText')"
                            :selectLabel="$t('selectOptionText')"
                            :selectedLabel="$t('selectOptionSelectedText')"
                            :selectGroupLabel="$t('selectGroupOptionsText')"
                            :deselectGroupLabel="$t('selectRemoveGroupOptionsText')"
                        >
                            <span slot="noResult">{{$t('selectNoResultsText')}}</span>
                            <span slot="noOptions">{{$t('selectNoOptionText')}}</span>
                            <span slot="maxElements">{{$t('selectMaxElemenetsText')}}</span>
                        </Multiselect>
                        <p
                            v-if="errors['victim.marital_status.id']"
                            class="text-red-500 small mt-4"
                        >
                            {{errors['victim.marital_status.id'][0]}}
                        </p>
                    </div>
                    <Input
                        v-model="caseAdd.victim.pid"
                        :error="errors['victim.pid']"
                        :class="[errors['victim.pid'] ? 'mb-4' : 'mb-20']"
                        :label="$t('victimCnpLabel')"
                        :placeholder="$t('victimCnpPlaceholder')"
                        type="text"
                        name="victim_cnp"
                    />
                    <Input
                        v-model="caseAdd.victim.phone"
                        :error="errors['victim.phone']"
                        :class="[errors['victim.phone'] ? 'mb-4' : 'mb-20']"
                        :label="$t('victimPhoneLabel')"
                        :placeholder="$t('victimPhonePlaceholder')"
                        type="text"
                        name="victim_phone"
                    />
                    <Input
                        v-model.number="caseAdd.victim.age"
                        :error="errors['victim.age']"
                        :class="[errors['victim.age'] ? 'mb-4' : 'mb-20']"
                        :label="$t('caseDetailsAge')"
                        :placeholder="$t('caseDetailsAge')"
                        type="number"
                        name="victim_age"
                    />
                    <Input
                        v-model="caseAdd.victim.birth_date"
                        :error="errors['victim.birth_date']"
                        :class="[errors['victim.birth_date'] ? 'mb-4' : 'mb-20']"
                        :label="$t('caseDetailsBirthDate')"
                        :placeholder="$t('caseDetailsBirthDatePlaceholder')"
                        type="string"
                        name="victim_birth_date"
                    />
                    <div class="mb-20">
                        <label class="flex">{{$t('victimGenderLabel')}}</label>
                        <Multiselect
                            v-model="caseAdd.victim.gender"
                            :options="genders"
                            class="rounded border"
                            :class="[errors['victim.gender'] ? 'border-red-500' : 'border-gray-200']"
                            label="name"
                            track-by="name"
                            :placeholder="$t('victimGenderPlaceholder')"
                            :deselectLabel="$t('selectRemoveOptionText')"
                            :selectLabel="$t('selectOptionText')"
                            :selectedLabel="$t('selectOptionSelectedText')"
                        >
                            <span slot="noResult">{{$t('selectNoResultsText')}}</span>
                            <span slot="noOptions">{{$t('selectNoOptionText')}}</span>
                        </Multiselect>
                        <p
                            v-if="errors['victim.gender']"
                            class="text-red-500 small mt-4"
                        >
                            {{errors['victim.gender.id'][0]}}
                        </p>
                    </div>
                </div>
                <!-- Domiciuliul legal -->
                <div class="grid sm:grid-cols-2 xl:grid-cols-3 gap-x-30 lg:mt-20">
                    <p class="mb-20 text-lg font-bold opacity-85 tracking-tight font-titillium-bold text-gray-800">{{$t('victimHomeText')}}</p>
                    <Radio
                        :error="errors['victim.legal_residence.type']"
                        :class="[errors['victim.legal_residence.type'] ? 'mb-4' : '']"
                        v-on:checked="victimLegalResidenceType"
                        class="mt-4"
                        :options="addressRadioOptions"
                        name="victim_home_urban_rural"
                    />
                </div>
                <div class="grid lg:grid-cols-2 xl:grid-cols-3 gap-x-30">
                    <div class="mb-20">
                        <label class="flex">{{$t('victimHomeCountyLabel')}}</label>
                        <Multiselect
                            v-model="caseAdd.victim.legal_residence.county"
                            :options="counties"
                            @select="countySelectedLegalResidenceVictim"
                            class="rounded border"
                            :class="[errors['victim.legal_residence.county.id'] ? 'border-red-500' : 'border-gray-200']"
                            label="name"
                            track-by="name"
                            :placeholder="$t('victimHomeCountyPlaceholder')"
                            :deselectLabel="$t('selectRemoveOptionText')"
                            :selectLabel="$t('selectOptionText')"
                            :selectedLabel="$t('selectOptionSelectedText')"
                        >
                            <span slot="noResult">{{$t('selectNoResultsText')}}</span>
                            <span slot="noOptions">{{$t('selectNoOptionText')}}</span>
                        </Multiselect>
                        <p
                            v-if="errors['victim.legal_residence.county.id']"
                            class="text-red-500 small mt-4"
                        >
                            {{errors['victim.legal_residence.county.id'][0]}}
                        </p>
                    </div>
                    <div class="mb-20">
                        <label class="flex">{{$t('victimHomeCityLabel')}}</label>
                        <Multiselect
                            v-model="caseAdd.victim.legal_residence.city"
                            :options="victim_legal_residence_cities"
                            class="rounded border"
                            :class="[errors['victim.legal_residence.city.id'] ? 'border-red-500' : 'border-gray-200']"
                            label="name"
                            track-by="name"
                            :placeholder="$t('victimHomeCityPlaceholder')"
                            :deselectLabel="$t('selectRemoveOptionText')"
                            :selectLabel="$t('selectOptionText')"
                            :selectedLabel="$t('selectOptionSelectedText')"
                        >
                            <span slot="noResult">{{$t('selectNoResultsText')}}</span>
                            <span slot="noOptions">{{$t('selectNoOptionText')}}</span>
                        </Multiselect>
                        <p
                            v-if="errors['victim.legal_residence.city.id']"
                            class="text-red-500 small mt-4"
                        >
                            {{errors['victim.legal_residence.city.id'][0]}}
                        </p>
                    </div>
                    <Input
                        v-model="caseAdd.victim.legal_residence.address"
                        :error="errors['victim.legal_residence.address']"
                        :class="[errors['victim.legal_residence.address'] ? 'mb-4' : 'mb-20']"
                        :label="$t('victimHomeAddressLabel')"
                        :placeholder="$t('victimHomeAddressPlaceholder')"
                        type="text"
                        name="victim_home_address"
                    />
                </div>
                <!-- Resedinta actuala -->
                <div class="grid sm:grid-cols-2 xl:grid-cols-3 gap-x-30">
                    <p class="text-lg font-bold opacity-85 tracking-tight font-titillium-bold text-gray-800">{{$t('victimResidenceText')}}</p>
                    <Radio
                        :error="errors['victim.current_residence.type']"
                        :class="[errors['victim.current_residence.type'] ? 'mb-4': '']"
                        v-on:checked="victimCurrentResidenceType"
                        :options="addressRadioOptions"
                        name="victim_residence_urban_rural"
                        class="mt-4"
                    />
                </div>

                <div class="grid lg:grid-cols-2 xl:grid-cols-3 gap-x-30">
                    <div class="mb-20">
                        <label class="flex">{{$t('victimResidenceCountyLabel')}}</label>
                        <Multiselect
                            v-model="caseAdd.victim.current_residence.county"
                            @select="countySelectedCurrentResidenceVictim"
                            :options="counties"
                            class="rounded border"
                            :class="[errors['victim.current_residence.county.id'] ? 'border-red-500' : 'border-gray-200']"
                            label="name"
                            track-by="name"
                            :placeholder="$t('victimResidenceCountyPlaceholder')"
                            :deselectLabel="$t('selectRemoveOptionText')"
                            :selectLabel="$t('selectOptionText')"
                            :selectedLabel="$t('selectOptionSelectedText')"
                        >
                            <span slot="noResult">{{$t('selectNoResultsText')}}</span>
                            <span slot="noOptions">{{$t('selectNoOptionText')}}</span>
                        </Multiselect>
                        <p
                            v-if="errors['victim.current_residence.county.id']"
                            class="text-red-500 small mt-4"
                        >
                            {{errors['victim.current_residence.county.id'[0]]}}
                        </p>
                    </div>
                    <div class="mb-20">
                        <label class="flex">{{$t('victimResidenceCityLabel')}}</label>
                        <Multiselect
                            v-model="caseAdd.victim.current_residence.city"
                            :options="victim_current_residence_cities"
                            class="rounded border"
                            :class="[errors['victim.current_residence.city.id'] ? 'border-red-500' : 'border-gray-200']"
                            label="name"
                            track-by="name"
                            :placeholder="$t('victimResidenceCityPlaceholder')"
                            :deselectLabel="$t('selectRemoveOptionText')"
                            :selectLabel="$t('selectOptionText')"
                            :selectedLabel="$t('selectOptionSelectedText')"
                        >
                            <span slot="noResult">{{$t('selectNoResultsText')}}</span>
                            <span slot="noOptions">{{$t('selectNoOptionText')}}</span>
                        </Multiselect>
                        <p
                            v-if="errors['victim.current_residence.city.id']"
                            class="text-red-500 small mt-4"
                        >
                            {{errors['victim.current_residence.city.id'][0]}}
                        </p>
                    </div>
                    <Input
                        v-model="caseAdd.victim.current_residence.address"
                        class=""
                        :error="errors['victim.current_residence.address']"
                        :class="[errors['victim.current_residence.address'] ? 'mb-4' : 'mb-20 xl:mb-40']"
                        :label="$t('victimResidenceAddressLabel')"
                        :placeholder="$t('victimResidenceAddressPlaceholder')"
                        type="text"
                        name="victim_residence_address"
                    />
                    <!-- Date personale 2 -->
                    <div class="mb-20 ">
                        <label class="flex">{{$t('victimCitizenshipLabel')}}</label>
                        <Multiselect
                            v-model="caseAdd.victim.citizenships"
                            :options="citizenshipsAndNationalities"
                            :multiple="true"
                            class="rounded border"
                            :class="[errors['victim.citizenships.*.id'] ? 'border-red-500' : 'border-gray-200']"
                            label="name"
                            track-by="name"
                            :placeholder="$t('victimCitizenshipPlaceholder')"
                            :deselectLabel="$t('selectRemoveOptionText')"
                            :selectLabel="$t('selectOptionText')"
                            :selectedLabel="$t('selectOptionSelectedText')"
                            :selectGroupLabel="$t('selectGroupOptionsText')"
                            :deselectGroupLabel="$t('selectRemoveGroupOptionsText')"
                        >
                            <span slot="noResult">{{$t('selectNoResultsText')}}</span>
                            <span slot="noOptions">{{$t('selectNoOptionText')}}</span>
                            <span slot="maxElements">{{$t('selectMaxElemenetsText')}}</span>
                        </Multiselect>
                        <p
                            v-if="errors['victim.citizenships.*.id']"
                            class="text-red-500 small mt-4"
                        >
                            {{errors['victim.citizenships.*.id'][0]}}
                        </p>
                    </div>
                    <div class="mb-20">
                        <label class="flex">{{$t('victimNationalityLabel')}}</label>
                        <Multiselect
                            v-model="caseAdd.victim.nationality"
                            :options="citizenshipsAndNationalities"
                            class="rounded border"
                            :class="[errors['victim.nationality.id'] ? 'border-red-500' : 'border-gray-200']"
                            label="name"
                            track-by="name"
                            :placeholder="$t('victimNationalityPlaceholder')"
                            :deselectLabel="$t('selectRemoveOptionText')"
                            :selectLabel="$t('selectOptionText')"
                            :selectedLabel="$t('selectOptionSelectedText')"
                        >
                            <span slot="noResult">{{$t('selectNoResultsText')}}</span>
                            <span slot="noOptions">{{$t('selectNoOptionText')}}</span>
                        </Multiselect>
                        <p
                            v-if="errors['victim.nationality.id']"
                            class="text-red-500 small mt-4"
                        >
                            {{errors['victim.nationality.id'][0]}}
                        </p>
                    </div>
                    <div class="mb-20">
                        <label class="flex">{{$t('victimReligionLabel')}}</label>
                        <Multiselect
                            v-model="caseAdd.victim.religion"
                            :options="religions"
                            class="rounded border"
                            :class="[errors['victim.religion.id'] ? 'border-red-500' : 'border-gray-200']"
                            label="name"
                            track-by="name"
                            :placeholder="$t('victimReligionPlaceholder')"
                            :deselectLabel="$t('selectRemoveOptionText')"
                            :selectLabel="$t('selectOptionText')"
                            :selectedLabel="$t('selectOptionSelectedText')"
                        >
                            <span slot="noResult">{{$t('selectNoResultsText')}}</span>
                            <span slot="noOptions">{{$t('selectNoOptionText')}}</span>
                        </Multiselect>
                        <p
                            v-if="errors['victim.religion.id']"
                            class="text-red-500 small mt-4"
                        >
                            {{errors['victim.religion.id'][0]}}
                        </p>
                    </div>
                </div>
                <div class="grid lg:grid-cols-2 xl:grid-cols-3 gap-x-30">
                    <div class="mb-20">
                        <label class="flex">{{$t('victimJobsLabel')}}</label>
                        <Multiselect
                            v-model="caseAdd.victim.occupations"
                            :options="occupations"
                            :multiple="true"
                            class="rounded border"
                            :class="[errors['victim.occupations.*.id'] ? 'border-red-500' : 'border-gray-200']"
                            label="name"
                            track-by="name"
                            :placeholder="$t('victimJobsPlaceholder')"
                            :deselectLabel="$t('selectRemoveOptionText')"
                            :selectLabel="$t('selectOptionText')"
                            :selectedLabel="$t('selectOptionSelectedText')"
                            :selectGroupLabel="$t('selectGroupOptionsText')"
                            :deselectGroupLabel="$t('selectRemoveGroupOptionsText')"
                        >
                            <span slot="noResult">{{$t('selectNoResultsText')}}</span>
                            <span slot="noOptions">{{$t('selectNoOptionText')}}</span>
                            <span slot="maxElements">{{$t('selectMaxElemenetsText')}}</span>
                        </Multiselect>
                        <p
                            v-if="errors['victim.occupations.*.id']"
                            class="text-red-500 small mt-4"
                        >
                            {{errors['victim.occupations.*.id'][0]}}
                        </p>
                    </div>
                    <div class="mb-20">
                        <label class="flex">{{$t('victimEducationLevelLabel')}}</label>
                        <Multiselect
                            v-model="caseAdd.victim.school_level"
                            :options="school_levels"
                            class="rounded border"
                            :class="[errors['victim.school_level.id'] ? 'border-red-500' : 'border-gray-200']"
                            label="name"
                            track-by="name"
                            :placeholder="$t('victimEducationLevelPlaceholder')"
                            :deselectLabel="$t('selectRemoveOptionText')"
                            :selectLabel="$t('selectOptionText')"
                            :selectedLabel="$t('selectOptionSelectedText')"
                        >
                            <span slot="noResult">{{$t('selectNoResultsText')}}</span>
                            <span slot="noOptions">{{$t('selectNoOptionText')}}</span>
                        </Multiselect>
                        <p
                            v-if="errors['victim.school_level.id']"
                            class="text-red-500 small mt-4"
                        >
                            {{errors['victim.school_level.id'][0]}}
                        </p>
                    </div>
                </div>
                <div class="grid lg:grid-cols-2 xl:grid-cols-3 gap-x-30">
                    <Input
                        v-model="caseAdd.victim.job"
                        :error="errors['victim.job']"
                        :class="[errors['victim.job'] ? 'mb-4' : 'mb-20']"
                        :label="$t('victimEmployerLabel')"
                        :placeholder="$t('victimEmployerPlaceholder')"
                        type="text"
                        name="victim_employer"
                    />
                    <div class="mb-20">
                        <label class="flex">{{$t('victimSalaryLabel')}}</label>
                        <Multiselect
                            v-model="caseAdd.victim.income_range"
                            :options="income_ranges"
                            class="rounded border"
                            :class="[errors['victim.income_ranges.id'] ? 'border-red-500' : 'border-gray-200']"
                            label="name"
                            track-by="name"
                            :placeholder="$t('victimSalaryPlaceholder')"
                            :deselectLabel="$t('selectRemoveOptionText')"
                            :selectLabel="$t('selectOptionText')"
                            :selectedLabel="$t('selectOptionSelectedText')"
                        >
                            <span slot="noResult">{{$t('selectNoResultsText')}}</span>
                            <span slot="noOptions">{{$t('selectNoOptionText')}}</span>
                        </Multiselect>
                        <p
                            v-if="errors['victim.income_ranges.id']"
                            class="text-red-500 small mt-4"
                        >
                            {{errors['victim.income_ranges.id'][0]}}
                        </p>
                    </div>
                </div>
                <!-- Persoane aflate in intretinere -->
                <div class="grid sm:grid-cols-2 xl:grid-cols-3 gap-x-30 mb-20 lg:mt-20">
                    <p class="text-lg font-bold opacity-85 tracking-tight font-titillium-bold text-gray-800">{{$t('victimPeopleInCareText')}}</p>
                    <Toggle
                        v-on:input='victimDependents'
                        :error="errors['victim.has_dependents']"
                        :class="[errors['victim.has_dependents'] ? 'mb-4' : '']"
                        class="mt-4"
                        :label1="$t('toggleNo')"
                        :label2="$t('toggleYes')"
                        id="victim_takingCareOfOtherPeople"
                    />
                </div>
                <div class="grid lg:grid-cols-2 xl:grid-cols-6 gap-x-30">
                    <Input
                        v-if="showVictimDependentsFields"
                        v-model="caseAdd.victim.children_dependents_number"
                        :error="errors['victim.children_dependents_number']"
                        :class="[errors['victim.children_dependents_number'] ? 'mb-4' : 'mb-20']"
                        :label="$t('victimPeopleInCareBelow10Label')"
                        :placeholder="$t('victimPeopleInCareBelow10Placeholder')"
                        type="text"
                        name="victim_peopleInCareBelow10"
                    />
                    <Input
                        v-if="showVictimDependentsFields"
                        v-model="caseAdd.victim.teenagers_dependents_number"
                        :error="errors['victim.teenagers_dependents_number']"
                        :class="[errors['victim.teenagers_dependents_number'] ? 'mb-4' : 'mb-20']"
                        :label="$t('victimPeopleInCareBetween10to18Label')"
                        :placeholder="$t('victimPeopleInCareBetween10to18Placeholder')"
                        type="text"
                        name="victim_peopleInCareBetween10to18"
                    />
                    <Input
                        v-if="showVictimDependentsFields"
                        v-model="caseAdd.victim.adults_dependents_number"
                        :error="errors['victim.adults_dependents_number']"
                        :class="[errors['victim.adults_dependents_number'] ? 'mb-4' : 'mb-20']"
                        :label="$t('victimPeopleInCareAbove18Label')"
                        :placeholder="$t('victimPeopleInCareAbove18Placeholder')"
                        type="text"
                        name="victim_peopleInCareAbove18"
                    />
                    <Input
                        v-if="showVictimDependentsFields"
                        v-model="caseAdd.victim.elderly_dependents_number"
                        :error="errors['victim.elderly_dependents_number']"
                        :class="[errors['victim.elderly_dependents_number'] ? 'mb-4' : 'mb-20']"
                        :label="$t('victimPeopleInCareAbove65Label')"
                        :placeholder="$t('victimPeopleInCareAbove65Placeholder')"
                        type="text"
                        name="victim_peopleInCareAbove65"
                    />
                </div>
                <!-- Exista medic de familie -->
                <div class="grid sm:grid-cols-2 xl:grid-cols-3 gap-x-30 mb-20">
                    <p class="flex align-center text-lg opacity-85 tracking-tight font-bold font-titillium-bold text-gray-800">{{$t('victimFamilyMedicText')}}</p>
                    <Toggle
                        v-on:input='victimMedic'
                        :error="errors['victim.has_family_doctor']"
                        :class="[errors['victim.has_family_doctor'] ? 'mb-4' : '']"
                        class="mt-4"
                        :label1="$t('toggleNo')"
                        :label2="$t('toggleYes')"
                        id="victim_family_medic"
                    />
                </div>
                <div class="grid lg:grid-cols-2 xl:grid-cols-3 gap-x-30">
                    <Input
                        v-if="showVictimMedicFields"
                        v-model="caseAdd.victim.family_doctor_name"
                        :error="errors['victim.family_doctor_name']"
                        :class="[errors['victim.family_doctor_name'] ? 'mb-4' : 'mb-20']"
                        :label="$t('victimFamilyMedicNameLabel')"
                        :placeholder="$t('victimFamilyMedicNamePlaceholder')"
                        type="text"
                        name="victim_family_medic_name"
                    />
                    <Input
                        v-if="showVictimMedicFields"
                        v-model="caseAdd.victim.family_doctor_phone"
                        :error="errors['victim.family_doctor_phone']"
                        :class="[errors['victim.family_doctor_phone'] ? 'mb-4' : 'mb-20']"
                        :label="$t('victimFamilyMedicPhoneLabel')"
                        :placeholder="$t('victimFamilyMedicPhonePlaceholder')"
                        type="text"
                        name="victim_family_medic_phone"
                    />
                </div>
                <!-- Antecedente psihiatrice -->
                <div class="grid md:grid-cols-2 xl:grid-cols-3 gap-x-30">
                    <p class="mb-20 flex align-center opacity-85 tracking-tight text-lg font-bold font-titillium-bold text-gray-800">{{$t('victimPsychiatricPastText')}}</p>
                    <Radio
                        :error="errors['victim.has_psychiatric_history']"
                        v-on:checked='victimPastPsychiatricProblems'
                        :options="pastPsychiatricProblems"
                        name="victim_pastPsychiatricProblems"
                        class="mt-4"
                    />
                </div>
                <div class="grid grid-cols-12">
                    <Input
                        v-if="showVictimPsychiatricField"
                        v-model="caseAdd.victim.psychiatric_history"
                        :error="errors['victim.psychiatric_history']"
                        :class="[errors['victim.psychiatric_history'] ? 'mb-4' : 'mb-20']"
                        class="col-start-1 col-end-13 xl:col-end-9"
                        :label="$t('victimPsychiatricPastLabel')"
                        :placeholder="$t('victimPsychiatricPastPlaceholder')"
                        type="text"
                        name="victim_psychiatricPast"
                    />
                </div>
                <!-- Antecedente penale -->
                <div class="grid md:grid-cols-2 xl:grid-cols-3 gap-x-30">
                    <p class="mb-20 flex align-center opacity-85 tracking-tight text-lg font-bold font-titillium-bold text-gray-800">{{$t('victimCriminalBackgroundText')}}</p>
                    <Radio
                        :error="errors['victim.has_criminal_records']"
                        v-on:checked='victimCriminalBackground'
                        :options="criminalBackground"
                        name="victim_criminalBackgroundBool"
                        class="mt-4"
                    />
                </div>
                <div class="grid grid-cols-12">
                    <Input
                        v-if="showVictimCriminalRecordField"
                        v-model="caseAdd.victim.criminal_records"
                        class="col-start-1 col-end-13 xl:col-end-9"
                        :error="errors['victim.criminal_records']"
                        :class="[errors['victim.criminal_records'] ? 'mb-4' : '']"
                        :label="$t('victimCriminalBackgroundLabel')"
                        :placeholder="$t('victimCriminalBackgroundPlaceholder')"
                        type="text"
                        name="victim_criminalBackground"
                    />
                </div>
            </div>
            <div class="col-span-12 bg-white mx-30 mb-30 rounded-lg p-20 shadow">
                <!-- Date despre agresor -->
                <Title
                    class="col-span-6"
                    :title="$t('aggressorDetailsTitle')"
                    :subtitle="$t('aggressorDetailsSubtitle')"
                />
                <hr class="col-span-12 mb-30">
                <!-- Relatia agresorului fata de victima -->
                <p class="col-span-12 mb-20 text-lg opacity-85 tracking-tight font-bold font-titillium-bold text-gray-800">{{$t('aggressorRelationWithVictimText')}}</p>
                <label class="flex">{{$t('aggressorRelationWithVictimLabel')}}</label>
                <div class="grid lg:grid-cols-2 xl:grid-cols-3 gap-x-30">
                    <div class="mb-20">
                        <label class="flex"></label>
                        <Multiselect
                            v-model="caseAdd.relationship_type"
                            :options="relationships"
                            class="rounded border"
                            :class="[errors['relationship_type.id'] ? 'border-red-500' : 'border-gray-200']"
                            label="name"
                            track-by="name"
                            :placeholder="$t('aggressorRelationWithVictimPlaceholder1')"
                            :deselectLabel="$t('selectRemoveOptionText')"
                            :selectLabel="$t('selectOptionText')"
                            :selectedLabel="$t('selectOptionSelectedText')"
                        >
                            <span slot="noResult">{{$t('selectNoResultsText')}}</span>
                            <span slot="noOptions">{{$t('selectNoOptionText')}}</span>
                        </Multiselect>
                        <p
                            v-if="errors['relationship_type.id']"
                            class="text-red-500 small mt-4"
                        >
                            {{errors['relationship_type.id'][0]}}
                        </p>
                    </div>
                    <Input
                        v-model="caseAdd.custom_relationship_type"
                        :error="errors['custom_relationship_type']"
                        :class="[errors['custom_relationship_type'] ? 'mb-4' : 'mb-20']"
                        :placeholder="$t('aggressorRelationWithVictimPlaceholder2')"
                        type="text"
                        name="aggressor_relation_with_victim"
                    />
                </div>
                <!-- Detalii personale -->
                <div class="grid lg:grid-cols-2 xl:grid-cols-3 gap-x-30">
                    <!-- Not used anymore -->
                    <!-- <Input
                        v-model="caseAdd.aggressor.first_name"
                        :error="errors['aggressor.first_name']"
                        :class="[errors['aggressor.first_name'] ? 'mb-4' : 'mb-20']"
                        :label="$t('aggressorFirstNameLabel')"
                        :placeholder="$t('aggressorFirstNamePlaceholder')"
                        type="text"
                        name="aggresor_first_name"
                    /> -->
                    <!-- <Input
                        v-model="caseAdd.aggressor.last_name"
                        :error="errors['aggressor.last_name']"
                        :class="[errors['aggressor.last_name'] ? 'mb-4' : 'mb-20']"
                        :label="$t('aggressorLastNameLabel')"
                        :placeholder="$t('aggressorLastNamePlaceholder')"
                        type="text"
                        name="aggresor_last_name"
                    /> -->
                    <div class="mb-20">
                        <label class="flex">{{$t('aggressorMaritalStatusLabel')}}</label>
                        <Multiselect
                            v-model="caseAdd.aggressor.marital_status"
                            :options="marital_statuses"
                            class="rounded border"
                            :class="[errors['aggressor.marital_status.id'] ? 'border-red-500' : 'border-gray-200']"
                            label="name"
                            track-by="name"
                            :placeholder="$t('aggressorMaritalStatusPlaceholder')"
                            :deselectLabel="$t('selectRemoveOptionText')"
                            :selectLabel="$t('selectOptionText')"
                            :selectedLabel="$t('selectOptionSelectedText')"
                            :selectGroupLabel="$t('selectGroupOptionsText')"
                            :deselectGroupLabel="$t('selectRemoveGroupOptionsText')"
                        >
                            <span slot="noResult">{{$t('selectNoResultsText')}}</span>
                            <span slot="noOptions">{{$t('selectNoOptionText')}}</span>
                            <span slot="maxElements">{{$t('selectMaxElemenetsText')}}</span>
                        </Multiselect>
                        <p
                            v-if="errors['aggressor.marital_status.id']"
                            class="text-red-500 small mt-4"
                        >
                            {{errors['aggressor.marital_status.id'][0]}}
                        </p>
                    </div>
                    <!-- Not used anymore -->
                    <!-- <Input
                        v-model="caseAdd.aggressor.pid"
                        :error="errors['aggressor.pid']"
                        :class="[errors['aggressor.pid'] ? 'mb-4' : 'mb-20']"
                        :label="$t('aggressorCnpLabel')"
                        :placeholder="$t('aggressorCnpPlaceholder')"
                        type="text"
                        name="aggresor_cnp"
                    /> -->
                    <!-- <Input
                        v-model="caseAdd.aggressor.phone"
                        :error="errors['aggressor.phone']"
                        :class="[errors['aggressor.phone'] ? 'mb-4' : 'mb-20']"
                        :label="$t('aggressorPhoneLabel')"
                        :placeholder="$t('aggressorPhonePlaceholder')"
                        type="text"
                        name="aggresor_phone"
                    /> -->
                    <Input
                        v-model="caseAdd.aggressor.age"
                        :error="errors['aggressor.age']"
                        :class="[errors['aggressor.age'] ? 'mb-4' : 'mb-20']"
                        :label="$t('caseDetailsAge')"
                        :placeholder="$t('caseDetailsAge')"
                        type="text"
                        name="victim_phone"
                    />
                    <div class="mb-20">
                        <label class="flex">{{$t('aggressorGenderLabel')}}</label>
                        <Multiselect
                            v-model="caseAdd.aggressor.gender"
                            :options="genders"
                            class="rounded border"
                            :class="[errors['aggressor.gender'] ? 'border-red-500' : 'border-gray-200']"
                            label="name"
                            track-by="value"
                            :placeholder="$t('aggressorGenderPlaceholder')"
                            :deselectLabel="$t('selectRemoveOptionText')"
                            :selectLabel="$t('selectOptionText')"
                            :selectedLabel="$t('selectOptionSelectedText')"
                        >
                            <span slot="noResult">{{$t('selectNoResultsText')}}</span>
                            <span slot="noOptions">{{$t('selectNoOptionText')}}</span>
                        </Multiselect>
                        <p
                            v-if="errors['aggressor.gender']"
                            class="text-red-500 small mt-4"
                        >
                            {{errors['aggressor.gender'][0]}}
                        </p>
                    </div>
                </div>
                <!-- Domiciuliul legal -->
                <!-- Not used anymore -->
                <!-- <div class="grid sm:grid-cols-2 xl:grid-cols-3 gap-x-30 lg:mt-20">
                    <p class="text-lg font-bold opacity-85 tracking-tight font-titillium-bold text-gray-800">{{$t('aggressorHomeText')}}</p>
                    <Radio
                        :error="errors['aggressor.legal_residence.type']"
                        :class="[errors['aggressor.legal_residence.type'] ? 'mb-4' : '']"
                        v-on:checked="aggressorLegalResidenceType"
                        class="mt-4"
                        :options="addressRadioOptions"
                        name="aggresor_home_urban_rural"
                    />
                </div>
                <div class="grid lg:grid-cols-2 xl:grid-cols-3 gap-x-30">
                    <div class="mb-20">
                        <label class="flex">{{$t('aggressorHomeCountyLabel')}}</label>
                        <Multiselect
                            v-model="caseAdd.aggressor.legal_residence.county"
                            :options="counties"
                            @select="countySelectedLegalResidenceAggressor"
                            class="rounded border"
                            :class="[errors['aggressor.legal_residence.county.id'] ? 'border-red-500' : 'border-gray-200']"
                            label="name"
                            track-by="name"
                            :placeholder="$t('aggressorHomeCountyPlaceholder')"
                            :deselectLabel="$t('selectRemoveOptionText')"
                            :selectLabel="$t('selectOptionText')"
                            :selectedLabel="$t('selectOptionSelectedText')"
                        >
                            <span slot="noResult">{{$t('selectNoResultsText')}}</span>
                            <span slot="noOptions">{{$t('selectNoOptionText')}}</span>
                        </Multiselect>
                        <p
                            v-if="errors['aggressor.legal_residence.county.id']"
                            class="text-red-500 small mt-4"
                        >
                            {{errors['aggressor.legal_residence.county.id'][0]}}
                        </p>
                    </div>
                    <div class="mb-20">
                        <label class="flex">{{$t('aggressorHomeCityLabel')}}</label>
                        <Multiselect
                            v-model="caseAdd.aggressor.legal_residence.city"
                            :options="aggressor_legal_residence_cities"
                            class="rounded border"
                            :class="[errors['aggressor.legal_residence.city.id'] ? 'border-red-500' : 'border-gray-200']"
                            label="name"
                            track-by="name"
                            :placeholder="$t('aggressorHomeCityPlaceholder')"
                            :deselectLabel="$t('selectRemoveOptionText')"
                            :selectLabel="$t('selectOptionText')"
                            :selectedLabel="$t('selectOptionSelectedText')"
                        >
                            <span slot="noResult">{{$t('selectNoResultsText')}}</span>
                            <span slot="noOptions">{{$t('selectNoOptionText')}}</span>
                        </Multiselect>
                        <p
                            v-if="errors['aggressor.legal_residence.city.id']"
                            class="text-red-500 small mt-4"
                        >
                            {{errors['aggressor.legal_residence.city.id'][0]}}
                        </p>
                    </div>
                    <Input
                        v-model="caseAdd.aggressor.legal_residence.address"
                        :error="errors['aggressor.legal_residence.address']"
                        :class="[errors['aggressor.legal_residence.address'] ? 'mb-4' : 'mb-20']"
                        :label="$t('aggressorHomeAddressLabel')"
                        :placeholder="$t('aggressorHomeAddressPlaceholder')"
                        type="text"
                        name="aggresor_home_address"
                    />
                </div> -->
                <!-- Resedinta actuala -->
                <!-- Not used anymore -->
                <!-- <div class="grid sm:grid-cols-2 xl:grid-cols-3 gap-x-30">
                    <p class="text-lg font-bold opacity-85 tracking-tight font-titillium-bold text-gray-800">{{$t('aggressorResidenceText')}}</p>
                    <Radio
                        :error="errors['aggressor.current_residence.type']"
                        :class="[errors['aggressor.current_residence.type'] ? 'mb-4': '']"
                        v-on:checked="aggressorCurrentResidenceType"
                        class="mt-4"
                        :options="addressRadioOptions"
                        name="aggresor_residence_urban_rural"
                    />
                </div> -->
                <div class="grid lg:grid-cols-2 xl:grid-cols-3 gap-x-30">
                    <!-- Not used anymore -->
                    <!-- <div class="mb-20">
                        <label class="flex">{{$t('aggressorResidenceCountyLabel')}}</label>
                        <Multiselect
                            v-model="caseAdd.aggressor.current_residence.county"
                            :options="counties"
                            @select="countySelectedCurrentResidenceAggressor"
                            class="rounded border"
                            :class="[errors['aggressor.current_residence.county.id'] ? 'border-red-500' : 'border-gray-200']"
                            label="name"
                            track-by="name"
                            :placeholder="$t('aggressorResidenceCountyPlaceholder')"
                            :deselectLabel="$t('selectRemoveOptionText')"
                            :selectLabel="$t('selectOptionText')"
                            :selectedLabel="$t('selectOptionSelectedText')"
                        >
                            <span slot="noResult">{{$t('selectNoResultsText')}}</span>
                            <span slot="noOptions">{{$t('selectNoOptionText')}}</span>
                        </Multiselect>
                        <p
                            v-if="errors['aggressor.current_residence.county.id']"
                            class="text-red-500 small mt-4"
                        >
                            {{errors['aggressor.current_residence.county.id'][0]}}
                        </p>
                    </div>
                    <div class="mb-20">
                        <label class="flex">{{$t('aggressorResidenceCityLabel')}}</label>
                        <Multiselect
                            v-model="caseAdd.aggressor.current_residence.city"
                            :options="aggressor_current_residence_cities"
                            class="rounded border"
                            :class="[errors['aggressor.current_residence.city.id'] ? 'border-red-500' : 'border-gray-200']"
                            label="name"
                            track-by="name"
                            :placeholder="$t('aggressorResidenceCityPlaceholder')"
                            :deselectLabel="$t('selectRemoveOptionText')"
                            :selectLabel="$t('selectOptionText')"
                            :selectedLabel="$t('selectOptionSelectedText')"
                        >
                            <span slot="noResult">{{$t('selectNoResultsText')}}</span>
                            <span slot="noOptions">{{$t('selectNoOptionText')}}</span>
                        </Multiselect>
                        <p
                            v-if="errors['aggressor.current_residence.city.id']"
                            class="text-red-500 small mt-4"
                        >
                            {{errors['aggressor.current_residence.city.id'][0]}}
                        </p>
                    </div>
                    <Input
                        v-model="caseAdd.aggressor.current_residence.address"
                        :error="errors['aggressor.current_residence.address']"
                        :class="[errors['aggressor.current_residence.address'] ? 'mb-4' : 'mb-29 xl:mb-40']"
                        :label="$t('aggressorResidenceAddressLabel')"
                        :placeholder="$t('aggressorResidenceAddressPlaceholder')"
                        type="text"
                        name="aggresor_residence_address"
                    /> -->
                    <!-- Date personale 2 -->
                    <!-- <div class="mb-20">
                        <label class="flex">{{$t('aggressorCitizenshipLabel')}}</label>
                        <Multiselect
                            v-model="caseAdd.aggressor.citizenships"
                            :options="citizenshipsAndNationalities"
                            :multiple="true"
                            class="rounded border"
                            :class="[errors['aggressor.citizenships.*.id'] ? 'border-red-500' : 'border-gray-200']"
                            label="name"
                            track-by="name"
                            :placeholder="$t('aggressorCitizenshipPlaceholder')"
                            :deselectLabel="$t('selectRemoveOptionText')"
                            :selectLabel="$t('selectOptionText')"
                            :selectedLabel="$t('selectOptionSelectedText')"
                            :selectGroupLabel="$t('selectGroupOptionsText')"
                            :deselectGroupLabel="$t('selectRemoveGroupOptionsText')"
                        >
                            <span slot="noResult">{{$t('selectNoResultsText')}}</span>
                            <span slot="noOptions">{{$t('selectNoOptionText')}}</span>
                            <span slot="maxElements">{{$t('selectMaxElemenetsText')}}</span>
                        </Multiselect>
                        <p
                            v-if="errors['aggressor.citizenships.*.id']"
                            class="text-red-500 small mt-4"
                        >
                            {{errors['aggressor.citizenships.*.id'][0]}}
                        </p>
                    </div> -->
                    <!-- <div class="mb-20">
                        <label class="flex">{{$t('aggressorNationalityLabel')}}</label>
                        <Multiselect
                            v-model="caseAdd.aggressor.nationality"
                            :options="citizenshipsAndNationalities"
                            class="rounded border"
                            :class="[errors['aggressor.nationality.id'] ? 'border-red-500' : 'border-gray-200']"
                            label="name"
                            track-by="name"
                            :placeholder="$t('aggressorNationalityPlaceholder')"
                            :deselectLabel="$t('selectRemoveOptionText')"
                            :selectLabel="$t('selectOptionText')"
                            :selectedLabel="$t('selectOptionSelectedText')"
                        >
                            <span slot="noResult">{{$t('selectNoResultsText')}}</span>
                            <span slot="noOptions">{{$t('selectNoOptionText')}}</span>
                        </Multiselect>
                        <p
                            v-if="errors['aggressor.nationality.id']"
                            class="text-red-500 small mt-4"
                        >
                            {{errors['aggressor.nationality.id'][0]}}
                        </p>
                    </div> -->
                    <!-- <div class="mb-20">
                        <label class="flex">{{$t('aggressorReligionLabel')}}</label>
                        <Multiselect
                            v-model="caseAdd.aggressor.religion"
                            :options="religions"
                            class="rounded border"
                            :class="[errors['aggressor.religion.id'] ? 'border-red-500' : 'border-gray-200']"
                            label="name"
                            track-by="name"
                            :placeholder="$t('aggressorReligionPlaceholder')"
                            :deselectLabel="$t('selectRemoveOptionText')"
                            :selectLabel="$t('selectOptionText')"
                            :selectedLabel="$t('selectOptionSelectedText')"
                        >
                            <span slot="noResult">{{$t('selectNoResultsText')}}</span>
                            <span slot="noOptions">{{$t('selectNoOptionText')}}</span>
                        </Multiselect>
                        <p
                            v-if="errors['aggressor.religion.id']"
                            class="text-red-500 small mt-4"
                        >
                            {{errors['aggressor.religion.id'][0]}}
                        </p>
                    </div> -->
                </div>
                <div class="grid lg:grid-cols-2 xl:grid-cols-3 gap-x-30">
                    <div class="mb-20">
                        <label class="flex">{{$t('aggressorJobsLabel')}}</label>
                        <Multiselect
                            v-model="caseAdd.aggressor.occupations"
                            :options="occupations"
                            :multiple="true"
                            class="rounded border"
                            :class="[errors['aggressor.occupations.*.id'] ? 'border-red-500' : 'border-gray-200']"
                            label="name"
                            track-by="name"
                            :placeholder="$t('aggressorJobsPlaceholder')"
                            :deselectLabel="$t('selectRemoveOptionText')"
                            :selectLabel="$t('selectOptionText')"
                            :selectedLabel="$t('selectOptionSelectedText')"
                            :selectGroupLabel="$t('selectGroupOptionsText')"
                            :deselectGroupLabel="$t('selectRemoveGroupOptionsText')"
                        >
                            <span slot="noResult">{{$t('selectNoResultsText')}}</span>
                            <span slot="noOptions">{{$t('selectNoOptionText')}}</span>
                            <span slot="maxElements">{{$t('selectMaxElemenetsText')}}</span>
                        </Multiselect>
                        <p
                            v-if="errors['aggressor.occupations.*.id']"
                            class="text-red-500 small mt-4"
                        >
                            {{errors['aggressor.occupations.*.id'][0]}}
                        </p>
                    </div>
                    <div class="mb-20">
                        <label class="flex">{{$t('aggressorEducationLevelLabel')}}</label>
                        <Multiselect
                            v-model="caseAdd.aggressor.school_level"
                            :options="school_levels"
                            class="rounded border"
                            :class="[errors['aggressor.school_level.id'] ? 'border-red-500' : 'border-gray-200']"
                            label="name"
                            track-by="name"
                            :placeholder="$t('aggressorEducationLevelPlaceholder')"
                            :deselectLabel="$t('selectRemoveOptionText')"
                            :selectLabel="$t('selectOptionText')"
                            :selectedLabel="$t('selectOptionSelectedText')"
                        >
                            <span slot="noResult">{{$t('selectNoResultsText')}}</span>
                            <span slot="noOptions">{{$t('selectNoOptionText')}}</span>
                        </Multiselect>
                        <p
                            v-if="errors['aggressor.school_level.id']"
                            class="text-red-500 small mt-4"
                        >
                            {{errors['aggressor.school_level.id'][0]}}
                        </p>
                    </div>
                </div>
                <div class="grid lg:grid-cols-2 xl:grid-cols-3 gap-x-30">
                    <Input
                        v-model="caseAdd.aggressor.job"
                        :error="errors['aggressor.job']"
                        :class="[errors['aggressor.job'] ? 'mb-4' : 'mb-20']"
                        :label="$t('aggressorEmployerLabel')"
                        :placeholder="$t('aggressorEmployerPlaceholder')"
                        type="text"
                        name="aggresor_employer"
                    />
                    <div class="mb-20">
                        <label class="flex">{{$t('aggressorSalaryLabel')}}</label>
                        <Multiselect
                            v-model="caseAdd.aggressor.income_range"
                            :options="income_ranges"
                            class="rounded border"
                            :class="[errors['aggressor.income_ranges.id'] ? 'border-red-500' : 'border-gray-200']"
                            label="name"
                            track-by="name"
                            :placeholder="$t('aggressorSalaryPlaceholder')"
                            :deselectLabel="$t('selectRemoveOptionText')"
                            :selectLabel="$t('selectOptionText')"
                            :selectedLabel="$t('selectOptionSelectedText')"
                        >
                            <span slot="noResult">{{$t('selectNoResultsText')}}</span>
                            <span slot="noOptions">{{$t('selectNoOptionText')}}</span>
                        </Multiselect>
                        <p
                            v-if="errors['aggressor.income_ranges.id']"
                            class="text-red-500 small mt-4"
                        >
                            {{errors['aggressor.income_ranges.id'][0]}}
                        </p>
                    </div>
                </div>
                <!-- Persoane aflate in intretinere -->
                <!-- Not used anymore -->
                <!-- <div class="grid sm:grid-cols-2 xl:grid-cols-3 gap-x-30 mb-20 lg:mt-20">
                    <p class="flex opacity-85 tracking-tight align-center text-lg font-bold font-titillium-bold text-gray-800">{{$t('aggressorPeopleInCareText')}}</p>
                    <Toggle
                        :error="errors['aggressor.has_dependents']"
                        :class="[errors['aggressor.has_dependents'] ? 'mb-4' : '']"
                        v-on:input="aggressorDependents"
                        class="mt-4"
                        :label1="$t('toggleNo')"
                        :label2="$t('toggleYes')"
                        id="aggresor_takingCareOfOtherPeople"
                    />
                </div>
                <div class="grid lg:grid-cols-2 xl:grid-cols-6 gap-x-30">
                    <Input
                        v-if="showAggressorDependentsFields"
                        v-model="caseAdd.aggressor.children_dependents_number"
                        :error="errors['aggressor.children_dependents_number']"
                        :class="[errors['aggressor.children_dependents_number'] ? 'mb-4' : 'mb-20']"
                        :label="$t('aggressorPeopleInCareBelow10Label')"
                        :placeholder="$t('aggressorPeopleInCareBelow10Placeholder')"
                        type="text"
                        name="aggresor_peopleInCareBelow10"
                    />
                    <Input
                        v-if="showAggressorDependentsFields"
                        v-model="caseAdd.aggressor.teenagers_dependents_number"
                        :error="errors['aggressor.teenagers_dependents_number']"
                        :class="[errors['aggressor.teenagers_dependents_number'] ? 'mb-4' : 'mb-20']"
                        :label="$t('aggressorPeopleInCareBetween10to18Label')"
                        :placeholder="$t('aggressorPeopleInCareBetween10to18Placeholder')"
                        type="text"
                        name="aggresor_peopleInCareBetween10to18"
                    />
                    <Input
                        v-if="showAggressorDependentsFields"
                        v-model="caseAdd.aggressor.adults_dependents_number"
                        :error="errors['aggressor.adults_dependents_number']"
                        :class="[errors['aggressor.adults_dependents_number'] ? 'mb-4' : 'mb-20']"
                        :label="$t('aggressorPeopleInCareAbove18Label')"
                        :placeholder="$t('aggressorPeopleInCareAbove18Placeholder')"
                        type="text"
                        name="aggresor_peopleInCareAbove18"
                    />
                    <Input
                        v-if="showAggressorDependentsFields"
                        v-model="caseAdd.aggressor.elderly_dependents_number"
                        :error="errors['aggressor.elderly_dependents_number']"
                        :class="[errors['aggressor.elderly_dependents_number'] ? 'mb-4' : 'mb-20']"
                        :label="$t('aggressorPeopleInCareAbove65Label')"
                        :placeholder="$t('aggressorPeopleInCareAbove65Placeholder')"
                        type="text"
                        name="aggresor_peopleInCareBelow65"
                    />
                </div> -->
                <!-- Acte anterioare de violenta in familie -->
                <div class="grid sm:grid-cols-2 xl:grid-cols-3 gap-x-30 mb-20">
                    <p class="flex opacity-85 tracking-tight align-center text-lg font-bold font-titillium-bold text-gray-800">{{$t('aggressorFamilyViolenceText')}}</p>
                    <Toggle
                        :error="errors['aggressor.has_domestic_violence']"
                        :class="[errors['aggressor.has_domestic_violence'] ? 'mb-4' : '']"
                        v-on:input="aggressorDomesticViolence"
                        class="mt-4"
                        :label1="$t('toggleNo')"
                        :label2="$t('toggleYes')"
                        id="aggresor_family_violence"
                    />
                </div>
                <div
                    v-if="showAggressorDomesticViolenceField"
                    class="mb-20 grid grid-cols-12"
                >
                    <div class="col-start-1 col-end-13 xl:col-end-9">
                        <label class="flex">{{$t('aggressorFamilyViolenceLabel')}}</label>
                        <Multiselect
                            v-model="caseAdd.aggressor.domestic_violence_cases"
                            :options="domestic_violences"
                            :multiple="true"
                            class="rounded border"
                            :class="[errors['aggressor.domestic_violence_cases.*.id'] ? 'border-red-500' : 'border-gray-200']"
                            label="name"
                            track-by="name"
                            :placeholder="$t('aggressorFamilyViolencePlaceholder')"
                            :deselectLabel="$t('selectRemoveOptionText')"
                            :selectLabel="$t('selectOptionText')"
                            :selectedLabel="$t('selectOptionSelectedText')"
                            :selectGroupLabel="$t('selectGroupOptionsText')"
                            :deselectGroupLabel="$t('selectRemoveGroupOptionsText')"
                        >
                            <span slot="noResult">{{$t('selectNoResultsText')}}</span>
                            <span slot="noOptions">{{$t('selectNoOptionText')}}</span>
                            <span slot="maxElements">{{$t('selectMaxElemenetsText')}}</span>
                        </Multiselect>
                        <p
                            v-if="errors['aggressor.domestic_violence_cases.*.id']"
                            class="text-red-500 small mt-4"
                        >
                            {{errors['aggressor.domestic_violence_cases.*.id'][0]}}
                        </p>
                    </div>
                </div>
                <!-- Antecedente psihiatrice -->
                <div class="grid md:grid-cols-2 xl:grid-cols-3 gap-x-30">
                    <p class="mb-20 opacity-85 tracking-tight flex align-center text-lg font-bold font-titillium-bold text-gray-800">{{$t('aggressorPsychiatricPastText')}}</p>
                    <Radio
                        :error="errors['aggressor.has_psychiatric_history']"
                        :class="[errors['aggressor.has_psychiatric_history'] ? 'mb-4' : '']"
                        class="mt-4"
                        v-on:checked="aggressorPastPsychiatricProblems"
                        :options="pastPsychiatricProblems"
                        name="aggresor_pastPsychiatricProblemsBool"
                    />
                </div>
                <div class="grid grid-cols-12">
                    <Input
                        v-if="showAggressorPsychiatricField"
                        v-model="caseAdd.aggressor.psychiatric_history"
                        class="col-start-1 col-end-13 xl:col-end-9 mb-20"
                        :error="errors['aggressor.psychiatric_history']"
                        :class="[errors['aggressor.psychiatric_history'] ? 'mb-4' : 'mb-20']"
                        :label="$t('aggressorPsychiatricPastLabel')"
                        :placeholder="$t('aggressorPsychiatricPastPlaceholder')"
                        type="text"
                        name="aggresor_pastPsychiatricProblems"
                    />
                </div>
                <!-- Consum de substante -->
                <div class="grid md:grid-cols-2 xl:grid-cols-3 gap-x-30">
                    <p class="mb-20 opacity-85 tracking-tight flex align-center text-lg font-bold font-titillium-bold text-gray-800">{{$t('aggressorSubstanceUseText')}}</p>
                    <Radio
                        :error="errors['aggressor.has_substance_use']"
                        :class="[errors['aggressor.has_substance_use'] ? 'mb-4' : '']"
                        class="mt-4"
                        v-on:checked="aggressorSubstances"
                        :options="substanceUse"
                        name="aggresor_substanceUse"
                    />
                </div>
                <div class="grid grid-cols-12">
                    <div
                        v-if="showAggressorSubstanceUseField"
                        class="mb-20 col-start-1 col-end-13 xl:col-end-9"
                    >
                        <label class="flex">{{$t('aggressorSubstanceUseLabel')}}</label>
                        <Multiselect
                            v-model="caseAdd.aggressor.substance_use_cases"
                            :options="substances"
                            :multiple="true"
                            class="rounded border"
                            :class="[errors['aggressor.substance_use_cases'] ? 'border-red-500' : 'border-gray-200']"
                            label="name"
                            track-by="name"
                            :placeholder="$t('aggressorSubstanceUsePlaceholder')"
                            :deselectLabel="$t('selectRemoveOptionText')"
                            :selectLabel="$t('selectOptionText')"
                            :selectedLabel="$t('selectOptionSelectedText')"
                            :selectGroupLabel="$t('selectGroupOptionsText')"
                            :deselectGroupLabel="$t('selectRemoveGroupOptionsText')"
                        >
                            <span slot="noResult">{{$t('selectNoResultsText')}}</span>
                            <span slot="noOptions">{{$t('selectNoOptionText')}}</span>
                            <span slot="maxElements">{{$t('selectMaxElemenetsText')}}</span>
                        </Multiselect>
                        <p
                            v-if="errors['aggressor.substance_use_cases']"
                            class="text-red-500 small mt-4"
                        >
                            {{errors['aggressor.substance_use_cases'][0]}}
                        </p>
                    </div>
                </div>
                <!-- Antecedente penale -->
                <div class="grid md:grid-cols-2 xl:grid-cols-3 gap-x-30">
                    <p class="mb-20 opacity-85 tracking-tight text-lg font-bold font-titillium-bold text-gray-800">{{$t('aggressorCriminalBackgroundText')}}</p>
                    <Radio
                        :error="errors['aggressor.has_criminal_records']"
                        :class="[errors['aggressor.has_criminal_records'] ? 'mb-4' : '']"
                        class="mt-4"
                        v-on:checked="aggressorCriminalBackground"
                        :options="criminalBackground"
                        name="aggresor_criminalBackgroundBool"
                    />
                </div>
                <div class="grid grid-cols-12">
                    <Input
                        v-if="showAggressorCriminalRecordField"
                        v-model="caseAdd.aggressor.criminal_records"
                        class="col-start-1 col-end-13 xl:col-end-9"
                        :error="errors['aggressor.criminal_records']"
                        :class="[errors['aggressor.criminal_records'] ? 'mb-4' : '']"
                        :label="$t('aggressorCriminalBackgroundLabel')"
                        :placeholder="$t('aggressorCriminalBackgroundPlaceholder')"
                        type="text"
                        name="aggresor_criminalBackground"
                    />
                </div>
            </div>
            <!-- Antecedente de caz din ultimul an -->
            <!-- Not used anymore -->
            <!-- <div class="col-span-12 bg-white mx-30 mb-30 rounded-lg p-20 shadow">
                <Title
                    class="col-span-6"
                    :title="$t('pastCasesLastYearTitle')"
                    :subtitle="$t('pastCasesLastYearSubtitle')"
                />
                <hr class="col-span-12 mb-30">
                <div class="grid lg:grid-cols-2 xl:grid-cols-3 gap-x-30">
                    <div>
                        <div class="mb-20 flex">
                            <p class="text-lg opacity-85 tracking-tight font-bold font-titillium-bold text-gray-800">{{$t('medicalCertificateText')}}</p>
                            <Toggle
                                :error="errors['has_forensic_certificate']"
                                :class="errors['has_forensic_certificate'] ? 'mb-4' : ''"
                                v-on:input="caseForensicCertificate"
                                class="mt-5 ml-20 lg:ml-10 xl:ml-20"
                                :label1="$t('toggleNo')"
                                :label2="$t('toggleYes')"
                                id="pastCase_medicalCertificate"
                            />
                        </div>
                        <div class="grid grid-cols-12">
                            <Input
                                v-if="showCaseForensicField"
                                v-model="caseAdd.forensic_certificate_number"
                                class="col-start-1 col-end-13 xl:col-end-7"
                                :error="errors['forensic_certificate_number']"
                                :class="[errors['forensic_certificate_number'] ? 'mb-4' : 'mb-20']"
                                :label="$t('certificatesNumberLabel')"
                                :placeholder="$t('certificatesNumberPlaceholder')"
                                type="text"
                                name="pastCase_certificatesNumber"
                            />
                        </div>
                    </div>
                    <div>
                        <div class="mb-20 flex">
                            <p class="text-lg opacity-85 tracking-tight font-bold font-titillium-bold text-gray-800">{{$t('daysOfMedicalCareText')}}</p>
                            <Toggle
                                :error="errors['has_medical_care']"
                                :class="[errors['has_medical_care'] ? 'mb-4' : '']"
                                v-on:input="caseMedicalCare"
                                class="mt-5 ml-20 lg:ml-10 xl:ml-20"
                                :label1="$t('toggleNo')"
                                :label2="$t('toggleYes')"
                                id="pastCase_medicalCare"
                            />
                        </div>
                        <div class="grid grid-cols-12">
                            <Input
                                v-if="showCaseMedicalCareField"
                                v-model="caseAdd.medical_care_days_number"
                                class="col-start-1 col-end-13 xl:col-end-7"
                                :error="errors['medical_care_days_number']"
                                :class="[errors['medical_care_days_number'] ? 'mb-4' : 'mb-20']"
                                :label="$t('daysOfMedicalCareNumberLabel')"
                                :placeholder="$t('daysOfMedicalCareNumberPlaceholder')"
                                type="text"
                                name="pastCase_medicalCareNumber"
                            />
                        </div>
                    </div>
                    <div>
                        <div class="mb-20 flex">
                            <p class="text-lg opacity-85 tracking-tight font-bold font-titillium-bold text-gray-800">{{$t('daysOfMedicalLeaveText')}}</p>
                            <Toggle
                                :error="errors['has_medical_leave']"
                                :class="[errors['has_medical_leave'] ? 'mb-4' : '']"
                                v-on:input="caseMedicalLeave"
                                class="mt-5 ml-20 lg:ml-10 xl:ml-20"
                                :label1="$t('toggleNo')"
                                :label2="$t('toggleYes')"
                                id="pastCase_medicalLeave"
                            />
                        </div>
                        <div class="grid grid-cols-12">
                            <Input
                                v-if="showCaseMedicalLeaveField"
                                v-model="caseAdd.medical_leave_days_number"
                                class="col-start-1 col-end-13 xl:col-end-7"
                                :error="errors['medical_leave_days_number']"
                                :class="[errors['medical_leave_days_number'] ? 'mb-4' : 'mb-30']"
                                :label="$t('daysOfMedicalLeaveNumberLabel')"
                                :placeholder="$t('daysOfMedicalLeaveNumberPlaceholder')"
                                type="text"
                                name="pastCasE_medicalLeaveNumber"
                            />
                        </div>
                    </div>
                    <div>
                        <div class="flex mb-20">
                            <p class="text-lg opacity-85 tracking-tight font-bold font-titillium-bold text-gray-800">{{$t('daysOfHospitalizationText')}}</p>
                            <Toggle
                                :error="errors['has_hospitalization']"
                                :class="[errors['has_hospitalization'] ? 'mb-4' : '']"
                                v-on:input="caseHospitalization"
                                class="mt-5 ml-20 lg:ml-10 xl:ml-20"
                                :label1="$t('toggleNo')"
                                :label2="$t('toggleYes')"
                                id="pastCase_hospitalization"
                            />
                        </div>
                        <div class="grid grid-cols-12">
                            <Input
                                v-if="showCaseHospitalizationField"
                                v-model="caseAdd.hospitalization_days_number"
                                class="col-start-1 col-end-13 xl:col-end-7"
                                :error="errors['hospitalization_days_number']"
                                :class="[errors['hospitalization_days_number'] ? 'mb-4' : '']"
                                :label="$t('daysOfHospitalizationLabel')"
                                :placeholder="$t('daysOfHospitalizationPlaceholder')"
                                type="text"
                                name="pastCase_hospitalizationNumber"
                            />
                        </div>
                    </div>
                </div>
            </div> -->
            <div class="col-span-12 ml-30 pl-20 mb-30 mr-20">
                <Checkbox
                    :error="errors['consent']"
                    :class="[errors['consent'] ? 'mb-4' : '']"
                    class="opacity-85 tracking-tight"
                    :labelTextStart="$t('termsAndConditionsText')"
                    labelTextEnd=""
                    linkText=""
                    link=''
                    name='termsAndConditions'
                    v-model="caseAdd.consent"
                />
            </div>
        </div>
        <div class="col-span-12 ml-30 pl-20 mr-30 pr-20 flex justify-between items-center">
            <Button
                @btnClick="cancelEdit"
                class="button bg-gray-500 hover:bg-gray-600 text-white w-150"
                role="submit"
                :text="$t('cancelButtonText')"
            />
            <div class="flex">
                <Button
                    @btnClick="onCaseAddDraft"
                    class="button bg-gray-500 hover:bg-gray-600 text-white w-257 mr-22"
                    role="submit"
                    :text="$t('saveAsDraftButtonText')"
                    :disabled="caseAddDraftLoading"
                    :spinning="caseAddDraftLoading"
                />
                <Button
                    @btnClick="onCaseAdd"
                    class="button bg-purple-500 text-white hover:bg-purple-600 w-150"
                    role="submit"
                    :text="$t('saveCaseButtonText')"
                    :disabled="caseAddLoading"
                    :spinning="caseAddLoading"
                />
            </div>
        </div>
    </div>
</template>

<script>
import Vue from 'vue'
import Button from '@/components/buttons/Button'
import Title from '@/components/texts/FormTitle'
import Multiselect from 'vue-multiselect'
import Input from '@/components/form/Input'
import Radio from '@/components/form/Radio'
import Toggle from '@/components/form/Toggle'
import Checkbox from '@/components/form/Checkbox'
import { mapGetters, mapActions } from 'vuex'
import SuccessBox from '@/components/success-message/SuccessBox'
import html2pdf from 'html2pdf.js'

export default {
    name: 'CaseAdd',
    components: {
        Button,
        Title,
        Multiselect,
        Input,
        Radio,
        Toggle,
        Checkbox
    },
    data () {
        return {
            addressRadioOptions: ['Urban', 'Rural'],
            pastPsychiatricProblems: ['Da', 'Nu', 'Necunoscut'],
            criminalBackground: ['Da', 'Nu', 'Necunoscut'],
            substanceUse: ['Da', 'Nu', 'Necunoscut'],
            victim_legal_residence_cities: [],
            victim_current_residence_cities: [],
            aggressor_legal_residence_cities: [],
            aggressor_current_residence_cities: [],
            showVictimDependentsFields: false,
            showAggressorDependentsFields: false,
            showVictimMedicFields: false,
            showVictimPsychiatricField: false,
            showAggressorPsychiatricField: false,
            showVictimCriminalRecordField: false,
            showAggressorCriminalRecordField: false,
            showAggressorDomesticViolenceField: false,
            showAggressorSubstanceUseField: false,
            showCaseForensicField: false,
            showCaseMedicalLeaveField: false,
            showCaseMedicalCareField: false,
            showCaseHospitalizationField: false,
            confirmationBox: false,
            pdfData: undefined,

            caseAddLoading: false,
            caseAddDraftLoading: false
        }
    },
    computed: {
        /** Get vuex state */
        ...mapGetters(['caseAdd', 'requestStatus', 'cases', 'counties', 'cities', 'token', 'errors', 'error', 'citizenshipsAndNationalities', 'income_ranges', 'marital_statuses', 'occupations', 'psychiatric_history', 'relationships', 'religions', 'school_levels', 'substances', 'criminal_records', 'domestic_violences', 'genders'])
    },
    methods: {
        /** Vuex actions. */
        ...mapActions(['cleanState', 'addCase', 'fetchCounties', 'fetchCitiesReturn', 'fetchCitizenshipsAndNationalities', 'fetchIncomeRanges', 'fetchMaritalStatuses', 'fetchOccupations', 'fetchPsychiatricHistory', 'fetchRelationships', 'fetchReligions', 'fetchSchoolLevels', 'fetchSubstances', 'fetchCriminalRecords', 'fetchDomesticViolences', 'fetchGenders']),

        async generatePDF (filename) {
            /** Remove errors to not save in PDF. */
            this.$store.commit('errors', '')

            const element = this.$refs.body
            const opt = {
                // margin: 1,
                filename: filename,
                html2canvas: { dpi: 196, letterRendering: true, scale: 4, useCORS: true },
                pagebreak: { mode: ['avoid-all', 'css', 'legacy'] },
                image: { type: 'jpeg', quality: 1 },
                // jsPDF: { unit: 'in', format: 'letter', orientation: 'portrait', precision: 16 }
                jsPDF: { unit: 'mm', format: 'a4', orientation: 'portrait' }
            }

            this.pdfData = await html2pdf().from(element).set(opt).toPdf().output('datauristring')
        },

        countySelectedLegalResidenceVictim (event) {
            /** Extract the cities of the selected county. */
            this.fetchCitiesReturn(event.id)
                .then(response => {
                    this.victim_legal_residence_cities = response
                })
        },
        countySelectedCurrentResidenceVictim (event) {
            /** Extract the cities of the selected county. */
            this.fetchCitiesReturn(event.id)
                .then(response => {
                    this.victim_current_residence_cities = response
                })
        },

        /*
            countySelectedLegalResidenceAggressor (event) {
                this.fetchCitiesReturn(event.id)
                    .then(response => {
                        this.aggressor_legal_residence_cities = response
                    })
            },
        */

        /*
            countySelectedCurrentResidenceAggressor (event) {
                this.fetchCitiesReturn(event.id)
                    .then(response => {
                        this.aggressor_current_residence_cities = response
                    })
            },
        */

        /** Functions to extract the values from the radio buttons addresses */
        victimLegalResidenceType (event) {
            this.caseAdd.victim.legal_residence.type = event
        },

        victimCurrentResidenceType (event) {
            this.caseAdd.victim.current_residence.type = event
        },

        /*
            aggressorLegalResidenceType (event) {
                this.caseAdd.aggressor.legal_residence.type = event
            },

            aggressorCurrentResidenceType (event) {
                this.caseAdd.aggressor.current_residence.type = event
            },
        */

        victimPastPsychiatricProblems (event) {
            this.caseAdd.victim.has_psychiatric_history = event === 'Da' ? 1 : (event === 'Nu' ? 0 : null)
            this.showVictimPsychiatricField = !(event === 'Nu' || event === 'Necunoscut')
            if (!(event === 'Nu' || event === 'Necunoscut')) {
                this.caseAdd.victim.psychiatric_history = ''
            }
        },

        victimCriminalBackground (event) {
            this.caseAdd.victim.has_criminal_records = event === 'Da' ? 1 : (event === 'Nu' ? 0 : null)
            this.showVictimCriminalRecordField = !(event === 'Nu' || event === 'Necunoscut')
            if (!(event === 'Nu' || event === 'Necunoscut')) {
                this.caseAdd.victim.criminal_records = ''
            }
        },

        aggressorPastPsychiatricProblems (event) {
            this.caseAdd.aggressor.has_psychiatric_history = event === 'Da' ? 1 : (event === 'Nu' ? 0 : null)
            this.showAggressorPsychiatricField = !(event === 'Nu' || event === 'Necunoscut')
            if (!(event === 'Nu' || event === 'Necunoscut')) {
                this.caseAdd.aggressor.psychiatric_history = ''
            }
        },

        aggressorCriminalBackground (event) {
            this.caseAdd.aggressor.has_criminal_records = event === 'Da' ? 1 : (event === 'Nu' ? 0 : null)
            this.showAggressorCriminalRecordField = !(event === 'Nu' || event === 'Necunoscut')
            if (!(event === 'Nu' || event === 'Necunoscut')) {
                this.caseAdd.aggressor.criminal_records = ''
            }
        },

        aggressorSubstances (event) {
            this.caseAdd.aggressor.has_substance_use = event === 'Da' ? 1 : (event === 'Nu' ? 0 : null)
            this.showAggressorSubstanceUseField = !(event === 'Nu' || event === 'Necunoscut')
            if (!(event === 'Nu' || event === 'Necunoscut')) {
                this.caseAdd.aggressor.substance_use_cases = []
            }
        },

        /** Functions for toggles */
        victimDependents (event) {
            this.caseAdd.victim.has_dependents = event ? 1 : 0
            this.showVictimDependentsFields = !!event
            if (!event) {
                this.caseAdd.victim.children_dependents_number = 0
                this.caseAdd.victim.teenagers_dependents_number = 0
                this.caseAdd.victim.adults_dependents_number = 0
                this.caseAdd.victim.elderly_dependents_number = 0
            }
        },

        victimMedic (event) {
            this.caseAdd.victim.has_family_doctor = event ? 1 : 0
            this.showVictimMedicFields = !!event
            if (!event) {
                this.caseAdd.victim.family_doctor_name = ''
                this.caseAdd.victim.family_doctor_phone = ''
            }
        },

        /*
            aggressorDependents (event) {
                this.caseAdd.aggressor.has_dependents = event ? 1 : 0
                this.showAggressorDependentsFields = !!event
                if (!event) {
                    this.caseAdd.aggressor.children_dependents_number = 0
                    this.caseAdd.aggressor.teenagers_dependents_number = 0
                    this.caseAdd.aggressor.adults_dependents_number = 0
                    this.caseAdd.aggressor.elderly_dependents_number = 0
                }
            },
        */

        aggressorDomesticViolence (event) {
            this.caseAdd.aggressor.has_domestic_violence = event ? 1 : 0
            this.showAggressorDomesticViolenceField = !!event
            if (!event) {
                this.caseAdd.aggressor.domestic_violence_cases = []
            }
        },

        /** functions for toggles for the common section of the case */

        /**
         * Not used anymore as the corresponding inputs are hidden.
         */
        /*
        caseForensicCertificate (event) {
            this.caseAdd.has_forensic_certificate = event ? 1 : 0
            this.showCaseForensicField = !!event
            if (!event) {
                this.caseAdd.forensic_certificate_number = ''
            }
        },

        caseMedicalCare (event) {
            this.caseAdd.has_medical_care = event ? 1 : 0
            this.showCaseMedicalCareField = !!event
            if (!event) {
                this.caseAdd.medical_care_days_number = 0
            }
        },

        caseMedicalLeave (event) {
            this.caseAdd.has_medical_leave = event ? 1 : 0
            this.showCaseMedicalLeaveField = !!event
            if (!event) {
                this.caseAdd.medical_leave_days_number = 0
            }
        },

        caseHospitalization (event) {
            this.caseAdd.has_hospitalization = event ? 1 : 0
            this.showCaseHospitalizationField = !!event
            if (!event) {
                this.caseAdd.hospitalization_days_number = 0
            }
        },
        */

        cancelEdit () {
            this.$router.push({ name: 'Cases' })
        },

        /** Function for the form submission */
        async onCaseAdd () {
            this.caseAddLoading = true
            const SuccessBoxComponent = Vue.extend(SuccessBox)
            window.scrollTo(0, 0)

            await this.generatePDF('GeneralCaseForm')

            this.caseAdd.status = 'registered'
            const payload = {
                token: this.token,
                case: {
                    case: this.caseAdd,
                    form_pdf: this.pdfData
                }
            }

            this.addCase(payload)
                .then(response => {
                    if (this.requestStatus === true) {
                        this.caseAddLoading = false
                        this.$router.push({ name: 'Cases', params: { showConfirmationBox: true } })
                        this.cleanState()
                    } else {
                        this.caseAddLoading = false
                        if (this.error !== '') {
                            /** Dynamically mount success box to page */
                            const instance = new SuccessBoxComponent({
                                propsData: { statusTitle: this.$t('servicesPageRequestErrorBoxTitle'), statusMessage: this.error, success: false }
                            })
                            instance.$mount()
                            this.$refs.successBoxContainer.appendChild(instance.$el)
                        }
                    }
                })
        },

        /** Function for case add as draft */
        onCaseAddDraft () {
            this.caseAddDraftLoading = true
            const SuccessBoxComponent = Vue.extend(SuccessBox)

            this.caseAdd.status = 'draft'
            const payload = {
                token: this.token,
                case: {
                    case: this.caseAdd
                }
            }
            this.addCase(payload)
                .then(response => {
                    if (this.requestStatus === true) {
                        this.caseAddDraftLoading = false
                        this.$router.push({ name: 'Cases', params: { showConfirmationBox: true } })
                        this.cleanState()
                    } else {
                        this.caseAddDraftLoading = false
                        if (this.error !== '') {
                            /** Dynamically mount success box to page */
                            const instance = new SuccessBoxComponent({
                                propsData: { statusTitle: this.$t('servicesPageRequestErrorBoxTitle'), statusMessage: this.error, success: false }
                            })
                            instance.$mount()
                            this.$refs.successBoxContainer.appendChild(instance.$el)
                        }
                    }
                })
        }
    },
    async created () {
        /** Extract all the static data for select inputs. */
        await this.fetchCounties()
        await this.fetchCitizenshipsAndNationalities({ token: this.token })
        await this.fetchIncomeRanges({ token: this.token })
        await this.fetchMaritalStatuses({ token: this.token })
        await this.fetchOccupations({ token: this.token })
        await this.fetchPsychiatricHistory({ token: this.token })
        await this.fetchRelationships({ token: this.token })
        await this.fetchReligions({ token: this.token })
        await this.fetchSchoolLevels({ token: this.token })
        await this.fetchSubstances({ token: this.token })
        await this.fetchCriminalRecords({ token: this.token })
        await this.fetchDomesticViolences({ token: this.token })
        await this.fetchGenders({ token: this.token })
    }
}
</script>
